import store from '@/store';

import { ApiError, UnauthorizedError } from './errors';

const apiBaseUrl = process.env.VUE_APP_API_URL;

async function fetchData(url, method, body) {
  let authHeaders = store.state.auth.currentAuthHeaders;

  const headers = new Headers({
    'Content-Type': 'application/json',
    ...authHeaders,
  });

  let init = {
    method: method,
    headers: headers,
    cache: 'no-cache',
  };

  if (body) init['body'] = JSON.stringify(body);

  const response = await fetch(`${apiBaseUrl}${url}`, init);
  const contentType = response.headers.get('content-type') || ''; // Default empty string so we can test using includes

  if (!response.ok && response.status == 401) {
    throw new UnauthorizedError();
  } else if (!response.ok) {
    let error_response;
    if (contentType.includes('application/json')) {
      error_response = await response.json();
    } else {
      error_response = await response.text();
    }
    throw new ApiError('API returned error', url, error_response);
  } else if (!contentType.includes('application/json')) {
    throw new ApiError('API returned invalid response', url, await response.text());
  }

  return await response.json();
}

function searchParamsFromFilters(filters) {
  let searchParams = new URLSearchParams();
  for (let filter in filters) {
    if (Array.isArray(filters[filter])) {
      for (let value of filters[filter]) searchParams.append(filter, value);
    } else if (filters[filter] != null) {
      // weak comparison on purpose, want to skip null/undefined
      searchParams.append(filter, filters[filter]);
    }
  }
  return searchParams;
}

export default {
  createLabels(order_ids, force = false, is_return = false, return_fallback = false) {
    return fetchData('order/label/create', 'POST', {
      order_ids,
      is_return,
      return_fallback,
      force,
    });
  },
  createOrder(order) {
    return fetchData('order/create', 'POST', order);
  },
  createOrderBulk(orders) {
    return fetchData('order/bulk', 'POST', orders);
  },
  generateTestOrders() {
    return fetchData(`order/generatetestorders`, 'POST');
  },
  createShop(cls, shop) {
    return fetchData('shop/create', 'POST', {
      shop_class: cls,
      ...shop,
    });
  },
  createCourier(cls, courier) {
    return fetchData('courier/create', 'POST', {
      courier_class: cls,
      ...courier,
    });
  },
  createPayment() {
    return fetchData('user/organisation/payment', 'POST');
  },
  deletePayment() {
    return fetchData('user/organisation/payment', 'DELETE');
  },
  getCourier(id) {
    return fetchData(`courier/${id}`, 'GET');
  },
  getCouriers() {
    return fetchData('courier/', 'GET');
  },
  getPlans() {
    return fetchData('plan/', 'GET');
  },
  getCourierMeta() {
    return fetchData('courier/meta', 'GET');
  },
  getInvoices() {
    return fetchData('invoice/', 'GET');
  },
  getInvoiceLabels(invoiceId) {
    return fetchData(`invoice/${invoiceId}/label/`, 'GET');
  },
  getInvoicePdf(invoiceId) {
    // TODO: refactor naming (getX/getXs is confusing)
    return fetchData(`invoice/${invoiceId}/pdf`, 'GET');
  },
  getInvoiceCsv(invoiceId) {
    // TODO: refactor naming (getX/getXs is confusing)
    return fetchData(`invoice/${invoiceId}/label/csv`, 'GET');
  },
  getLabel(labelId) {
    return fetchData(`label/${labelId}`, 'GET');
  },
  getLabels(filters) {
    if (!filters) filters = {};
    const searchParams = searchParamsFromFilters(filters);
    return fetchData(`label/?${searchParams}`, 'GET');
  },
  getLabelCount(filters) {
    if (!filters) filters = {};
    const searchParams = searchParamsFromFilters(filters);
    return fetchData(`label/count?${searchParams}`, 'GET');
  },
  getLabelPrices() {
    return fetchData('label-price/', 'GET');
  },
  getGdprRequests() {
    return fetchData('gdpr/', 'GET');
  },
  getOrder(orderId) {
    return fetchData(`order/${orderId}`, 'GET');
  },
  getOrders(filters) {
    if (!filters) filters = {};
    const searchParams = searchParamsFromFilters(filters);
    return fetchData(`order/?${searchParams}`, 'GET');
  },
  getOrderCount(filters) {
    if (!filters) filters = {};
    const searchParams = searchParamsFromFilters(filters);
    return fetchData(`order/count?${searchParams}`, 'GET');
  },
  getShopMeta() {
    return fetchData('shop/meta', 'GET');
  },
  getShop(id) {
    return fetchData(`shop/${id}`, 'GET');
  },
  getShops() {
    return fetchData('shop/', 'GET');
  },
  getUser() {
    return fetchData('user', 'GET');
  },
  getPdfLabels(labelIds, format = 'a6', positions = {}, sort = null) {
    let url = 'label/pdf';
    if (sort) url += `?sort=${sort}`;
    return fetchData(url, 'POST', {
      label_ids: labelIds,
      format,
      positions,
    });
  },
  getCourierCounters() {
    return fetchData('couriercounters/', 'GET');
  },
  getLabelCounters() {
    return fetchData('labelcounters/', 'GET');
  },
  printLabels(labelIds, printerClientId, format = 'a6', positions = {}, sort = null) {
    let url = 'label/print';
    if (sort) url += `?sort=${sort}`;
    return fetchData(url, 'POST', {
      label_ids: labelIds,
      printer_client_id: printerClientId,
      format,
      positions,
    });
  },
  getExportDoc(labelId) {
    return fetchData(`label/${labelId}/exportdoc`, 'GET');
  },
  resetCourier(id) {
    return fetchData(`courier/${id}/reset`, 'POST');
  },
  resetShop(id) {
    return fetchData(`shop/${id}/reset`, 'POST');
  },
  /**
   * Takes an array of order ids and resets them
   * @param {Array<number>} orderIds
   * @returns {Promise<any>}
   */
  resetOrders(orderIds) {
    return fetchData(`order/reset`, 'POST', orderIds);
  },
  searchOrder(filters) {
    if (!filters) filters = {};
    const searchParams = searchParamsFromFilters(filters);
    return fetchData(`order/search?${searchParams}`, 'GET');
  },
  update(service_type = null) {
    let url = 'update';
    if (service_type) url = `update/${service_type}`;
    return fetchData(url, 'POST');
  },
  updateCourier(id, updates) {
    return fetchData(`courier/${id}`, 'PATCH', updates);
  },
  updateLabel(label) {
    return fetchData(`label/${label.id}`, 'POST', label);
  },
  updateBulkLabels(data) {
    return fetchData('label/updatebulklabels', 'POST', data);
  },
  updateOrder(order) {
    return fetchData(`order/${order.id}`, 'POST', order);
  },
  updateBulkOrders(data) {
    return fetchData('order/updatebulkorders', 'POST', data);
  },
  packingSlipDoc(data) {
    return fetchData('order/packingslipdoc', 'POST', data);
  },
  updateShop(id, updates) {
    return fetchData(`shop/${id}`, 'PATCH', updates);
  },
  updateUserOrganisation(data) {
    return fetchData('user/organisation', 'PUT', data);
  },
  serviceOnboardRestore() {
    return fetchData('onboard/restore', 'POST');
  },
  subscribePlan(data) {
    return fetchData('user/organisation/plan', 'POST', data);
  },
  validateOrders(data) {
    return fetchData('order/update', 'POST', data);
  },
  sendTrackingTest(data) {
    return fetchData('trackingmail/sendtest', 'POST', data);
  },
  setTracking(data) {
    return fetchData(`tracking`, 'POST', data);
  },
  updateTracking(data) {
    return fetchData(`tracking`, 'PATCH', data);
  },
  setReturn(action) {
    return fetchData(`return/${action}`, 'POST');
  },
  payInvoice(invoiceId) {
    return fetchData(`invoice/${invoiceId}/payment`, 'POST');
  },
  // Start all ticket endpoints
  getTickets() {
    return fetchData(`ticket/`, 'GET');
  },
  getSingleTicket(ticketId) {
    return fetchData(`ticket/${ticketId}`, 'GET');
  },
  createTicket(data) {
    return fetchData('ticket/create', 'POST', data);
  },
  replyTicket(ticketId, data) {
    return fetchData(`ticket/${ticketId}/reply`, 'POST', data);
  },
  updateTicket(ticketId, data) {
    return fetchData(`ticket/${ticketId}`, 'PATCH', data);
  },
  /** @param {Array} labelIds */
  retryOrderUpdate(labelIds) {
    return fetchData('order/retry-update', 'POST', {
      label_ids: labelIds,
    });
  },
};
